import cn from 'classnames';
import Img from 'gatsby-image';
import React, { useEffect, useState, useMemo} from 'react';


import './PostCard.scss';
import { useIsMobile } from '../../../utils/windowDimensions';
import PostText from '../PostText/PostText';
import PostTags from '../PostTags/PostTags';
import { Link } from 'gatsby';


interface PostCardProps {
    post: Frontmatter;
    className?: string;
}

const PostCard: React.FC<PostCardProps> = ({
    post,
    className
}: PostCardProps) => {
    const isMobile = useIsMobile();
    const content = isMobile ? ""
    :
    <>
      <p className="content-text">{post.description}</p>
      {/* <PostTags tags={post.tags} /> */}
    </>
    
    return (
        <article className="post-card">
            {post.image && <Img fluid={post.image.childImageSharp.fluid} className="post-card-cover" />}
            <PostText
                category={post.tags[0]}
                author={post.author}
                date={"2020-05-26T14:08:39.672Z"}
                timeToRead={'8'}
                wrapClass="post-card-text"
                head={<Link to={post.suburl}>{post.title}</Link>}
            >
                {content}
            </PostText>
        </article>
    );
};

export default PostCard;
