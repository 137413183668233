import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useIsMobile } from '../../../utils/windowDimensions';

const PostText = (data: any) => {
  const isMobile = useIsMobile();
  const readTime = isMobile ? ''
    :
  <span>{` • ${data.timeToRead} min read`}</span>;
  const formatDate =
    Number.isNaN(data.date) === false ? new Date(data.date).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10);
  return (
    <div className={data.wrapClass}>
      {data.category && (
        <div className="cat-author">
          <Link className="post-category" to={`/categories/${_.kebabCase(data.category)}`}>
            {data.category}
          </Link>
          <div className="subtitle-caption">
            {data.author}
          </div>
      </div>
        )}
      <h3 className="post-title">{data.head}</h3>
      {data.children}
    </div>
  );
};

export default React.memo(PostText);
